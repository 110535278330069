import { useCallback, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import 'moment/locale/fr'
import { useAppContext } from '../context/appContext'
import { DateTime } from 'luxon'
import { CreateAppointmentModal, Agenda } from '.'

const localizer = momentLocalizer(moment)
const today = DateTime.now()
const DnDCalendar = withDragAndDrop(Calendar)

const CalendarView = () => {
	const { events, isUrgent, filterEmployee, filterDate, filterInvoiced, appointments, setCurrentAppointment, employees, clients, patchAppointment } = useAppContext()
	const [show, setShow] = useState(false)
	const [view, setView] = useState('day')
	const minTime = new Date()
	minTime.setHours(7, 0, 0)
	const eventsCalendar = events.map((event) => {
		event.start = new Date(event.start)
		event.end = new Date(event.end)
		const appointment = appointments && appointments.find(a => a.calendar_id === event.resource.id)
		event.invoiced = appointment?.invoiced
		if (
			!(isUrgent && !event.resource.urgent) &&
			!(filterEmployee.length !== 0 &&  filterEmployee.indexOf(event.resource.description) === -1) &&
			!(
				filterDate &&
				!(
					(typeof filterDate === 'string' && today.hasSame(event.start, filterDate)) ||
					(typeof filterDate === 'object' && DateTime.fromJSDate(filterDate).hasSame(event.start, 'day'))
				)
			) &&
			!(filterInvoiced !== null && !!event.invoiced != filterInvoiced)
		) {
			return event
		}
		return null
	})

	const resources = employees.map(e => {
		return {
			id: e.email_address,
			title: e.nom_de_lemploye,
		}
	})

	const handleShow = (e) => {
		const apmt = appointments && appointments.find(a => a.calendar_id === e.resource.id)
		setCurrentAppointment(apmt)
		setShow(true)
	}

	const moveEvent = useCallback(
		({event, start}) => {
			const apmt = appointments && appointments.find(a => a.calendar_id === event.resource.id)
			const currentClient = apmt && clients.filter(
				(client) => client.id === parseInt(apmt.client_id)
			)[0]
			start = DateTime.fromISO(start.toISOString())
			apmt && patchAppointment({
				...apmt,
				location: currentClient.acf?.localisation?.address || `pas d'adresse`,
				attendees: apmt.employee_id,
				client_name: currentClient.title?.rendered,
				// To be used in only mobile
				date_time: null,
				date: start.toISODate(),
				time: start.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }),
				zone: DateTime.now().zoneName,
			})
		},
		[appointments, clients, patchAppointment]
	)

	const eventStyleGetter = function(event, start, end, isSelected) {
		const opacity = isSelected ? 'FF' : 'CC'
		const apmt = appointments && appointments.find(apmt => apmt.calendar_id === event.resource.id)
		const emp = apmt && employees && employees.find(emp => emp.email_address === apmt.employee_id)
		var style = emp ? {
			backgroundColor: emp.couleur + opacity,
		} : {}
		if (view === 'day' || view === 'week') {
			style['minHeight'] = '4vh'
		}
		return {
			style: style
		}
	}

	return (
		<div className={'calendar-view fs-7 ' + view}>
			<DnDCalendar
				// view={view}
				// onView={setView}
				// tooltipAccessor={(e) => e.resource.description}
				defaultView='day'
				onSelectEvent={(e) => handleShow(e)}
				onEventDrop={moveEvent}
				onView={v => setView(v)}
				views={{
					month: true,
					week: true,
					day: true,
					agenda: Agenda,
				}}
				timeslots={1}
        step={60}
				// popup
				localizer={localizer}
				culture='fr'
				events={eventsCalendar}
				resources={view === 'day' ? resources : null}
				startAccessor='start'
				endAccessor='end'
				style={{ height: '83.5vh' }}
				eventPropGetter={eventStyleGetter}
				min={minTime}
				messages={{
					week: 'Semaine',
					work_week: 'Semaine de travail',
					day: 'Jour',
					month: 'Mois',
					previous: 'Précèdent',
					next: 'Suivant',
					today: `Aujourd'hui`,
					agenda: 'Ordre du jour',
					date: 'Date',
					time: 'Heure',
					event: 'Rendez-vous',
					invoiced: 'Facturé',
					allDay: 'Toute la journée',
					yesterday: 'Hier',
					tomorrow: 'Demain',

					noEventsInRange: `Il n'y a aucun rendez-vous dans cette plage.`,

					showMore: (total) => `+${total} plus`,
				}}
			/>

			<CreateAppointmentModal show={show} setShow={setShow} />
		</div>
	)
}

export default CalendarView
