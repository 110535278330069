const MapIcon = ({ size, color }) => {
	return (
		<svg
			version='1.0'
			xmlns='http://www.w3.org/2000/svg'
			width={size}
			height={size}
			viewBox='0 0 125 124'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0.000000,124.000000) scale(0.100000,-0.100000)'
				fill={color}
				stroke='none'
			>
				<path
					d='M574 913 c-33 -6 -102 -70 -126 -117 -22 -42 -27 -101 -18 -186 l5
-45 -151 -5 c-139 -5 -153 -7 -186 -30 -56 -38 -69 -76 -79 -235 -11 -163 -5
-217 26 -253 l23 -27 359 -3 c197 -2 369 0 381 3 38 9 81 64 87 109 3 23 -3
114 -13 204 l-20 163 24 67 c54 152 15 286 -99 341 -33 16 -61 21 -118 20 -41
-1 -84 -4 -95 -6z m157 -56 c110 -36 157 -151 108 -263 -21 -46 -76 -141 -122
-211 -45 -67 -87 -32 -176 143 -44 88 -51 109 -51 161 0 50 5 66 28 99 38 52
51 62 98 73 52 12 72 12 115 -2z m-208 -430 c21 -32 36 -65 33 -73 -5 -12 -42
-14 -233 -12 l-228 3 -3 43 c-3 36 1 47 27 72 l30 30 168 -2 167 -3 39 -58z
m301 -84 c13 -93 17 -210 7 -235 -15 -39 -61 -48 -257 -48 -133 0 -174 3 -183
14 -14 16 0 129 21 173 l13 28 142 3 141 3 48 49 c27 28 52 50 55 50 4 0 10
-17 13 -37z m-485 -72 c10 -7 11 -16 3 -42 -6 -19 -14 -56 -18 -83 -11 -77
-29 -89 -132 -84 -79 4 -87 7 -109 34 -21 25 -23 36 -19 81 12 105 9 103 143
103 65 0 124 -4 132 -9z'
				/>
				<path
					d='M624 780 c-34 -13 -64 -59 -64 -97 0 -54 54 -103 111 -103 51 1 83
23 103 72 35 83 -61 164 -150 128z m85 -74 c25 -30 -17 -70 -56 -55 -22 8 -32
47 -16 62 12 12 60 7 72 -7z'
				/>
			</g>
		</svg>
	)
}

export default MapIcon
