import { useState, useCallback, useMemo, useEffect } from 'react'
import { Spinner, Alert } from 'react-bootstrap'
import { useAppContext } from '../context/appContext'
import { AppointmentCard, CreateAppointmentModal } from '.'
import { DateTime } from 'luxon'

const Appointments = () => {
	const { isLoading, appointments, isUrgent, filterDate, filterInvoiced, highlighted_in_list, setCurrentAppointment } =
		useAppContext()
	const [show, setShow] = useState(false)
	const handleClick = useCallback((appointment) => {
		setCurrentAppointment(appointment)
		setShow(true)
	}, [setCurrentAppointment, setShow])
	const today = useMemo(() => DateTime.now(), [])
	useEffect(() => {
		const highlighteds = document.getElementsByClassName('highlighted');
		if (highlighteds.length > 0) {
			highlighteds[0].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
		}
	}, [highlighted_in_list])
	return (
		<div className='mb-5 p-4 bg-white appointments overflow-auto container-fluid'>
			<Alert variant='warning' show={isLoading}>
				<Spinner animation='border' variant='success' />
				Chargement
			</Alert>
			{appointments.map((appointment) => {
				if (
					(isUrgent && !appointment.urgent) ||
					// (filterEmployee.length !== 0 &&  filterEmployee.indexOf(appointment.employee_id) === -1) ||
					(filterDate && appointment.date_time &&
						!(
							(typeof filterDate === 'string' && today.hasSame(
								DateTime.fromISO(appointment.date_time),
								filterDate
							)) ||
							(typeof filterDate === 'object' && DateTime.fromJSDate(filterDate).hasSame(
								DateTime.fromISO(appointment.date_time),
								'day'
							))
						)) ||
					(appointment.employee_id && appointment.date_time) ||
					(filterInvoiced !== null && appointment.invoiced != filterInvoiced)
				) {
					// eslint-disable-next-line array-callback-return
					return
				}
				return (
					<AppointmentCard
						key={appointment.appointment_id}
						appointment={appointment}
						text_class='fs-6c'
						highlighted={highlighted_in_list === appointment.appointment_id}
						handleClick={handleClick}
					/>
				)
			})}
			<CreateAppointmentModal show={show} setShow={setShow} />
		</div>
	)
}

export default Appointments
