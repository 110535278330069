import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Dashboard, Login, Error } from './pages'

import 'bootstrap/dist/css/bootstrap.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

function App() {
	return (
		<BrowserRouter className='test'>
			<Routes className='test'>
				<Route  className='' path='/' element={<Dashboard />} />
				<Route path='/login' element={<Login />} />
				<Route path='*' element={<Error />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
