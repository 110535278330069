import { useState, useEffect, useCallback } from 'react'
import {
	useJsApiLoader,
	GoogleMap,
	MarkerF,
	InfoWindowF,
} from '@react-google-maps/api'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { useAppContext } from '../context/appContext'
import { MapInfoCard } from '.'
import { DateTime } from 'luxon'
import { CreateAppointmentModal } from '.'

const icons = {
	allSet: 'M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z',
	pending: 'M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.2 14.9H112z',
}

const containerStyle = {
	width: '100%',
	height: '84vh',
	// border: '1rem',
}

const CustomMarker = ({ handleDblClick, appointment, ...props }) => {
	const { highlightAppointmentInList } = useAppContext()
	const [infoWinVisible, setInfoWinVisible] = useState(false)
	const [marker, setMarker] = useState(null)

	const onLoad = useCallback((marker) => setMarker(marker), []);

	const handleMouseOver = () => {
		setInfoWinVisible(true)
		highlightAppointmentInList(appointment.appointment_id)
	}

	const handleMouseOut = () => {
		setInfoWinVisible(false)
		highlightAppointmentInList(null)
	}

	return (
		<MarkerF
			{...props}
			// size={{width:5, height:5}}
			onLoad={onLoad}
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
			onDblClick={() => handleDblClick(appointment)}
			onRightClick={() => marker.setZIndex((marker.getZIndex() || 0) - 1)}>
			{infoWinVisible &&
				<InfoWindowF
					className='test'
					position={props.position}
					options={{ maxWidth: 300 }}
				>
					<MapInfoCard {...appointment} />
				</InfoWindowF>
			}
		</MarkerF>
	)
}

const Map = () => {
	const {
		appointments,
		clients,
		isUrgent,
		filterEmployee,
		filterDate,
		filterInvoiced,
		setCurrentAppointment,
		employees,
		highlighted_in_map,
	} = useAppContext()
	const [show, setShow] = useState(false)
	const [markerGroups, setMarkerGroups] = useState([])
	const [markers, setMarkers] = useState([])
	const [map, setMap] = useState(null)
	const [zoom, setZoom] = useState(0)

	const onLoad = useCallback((map) => setMap(map), []);

	const handleDblClick = (appointment) => {
		setCurrentAppointment(appointment)
		setShow(true)
	}

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: 'AIzaSyC0mQYJIKIR6hm5SET_cnTA00Bnpn1q_nM',
	})

	useEffect(() => {
		const today = DateTime.now()
		let appointmentsMap = appointments.map((appointment, idx) => {
			if (appointment.finished) {
				return null
			}
			const client = clients.find(
				(client) => client.id === parseInt(appointment.client_id)
			)
			const employee = employees.find(e => e.email_address === appointment.employee_id)
			const markerObject = {
				idx: idx,
				isUrgent: appointment.urgent,
				employee_id: appointment.employee_id,
				appointment_id: appointment.appointment_id,
				color: employee?.couleur,
				position: {
					lat: client?.acf?.localisation?.lat,
					lng: client?.acf?.localisation?.lng,
				},
			}
			if (appointment.urgent) {
				if (appointment.employee_id && appointment.date_time) {
					markerObject.icon = icons.allSet
				} else {
					markerObject.icon = icons.pending
				}
			} else if (appointment.employee_id && appointment.date_time) {
				markerObject.icon = icons.allSet
			} else {
				markerObject.icon = icons.pending
			}
			if (
				markerObject.position.lat &&
				markerObject.position.lng &&
				!(isUrgent && !appointment.urgent) &&
				!(filterInvoiced !== null && appointment.invoiced != filterInvoiced) &&
				(
					!(appointment.employee_id && appointment.date_time) || 
					(
						!(filterEmployee.length !== 0 &&  filterEmployee.indexOf(appointment.employee_id) === -1) &&
						!(
							filterDate && appointment.date_time &&
							!(
								(
									filterDate === 'day' && today.hasSame(DateTime.fromISO(appointment.date_time), filterDate)
								) || (
									filterDate === 'week' && (
										DateTime.fromISO(appointment.date_time).diffNow('day').get('day') >= 0 &&
										DateTime.fromISO(appointment.date_time).diffNow('day').get('day') < 7
									)
								) || (
									typeof filterDate === 'object' && DateTime.fromJSDate(filterDate).hasSame(DateTime.fromISO(appointment.date_time), 'day')
								)
							)
						)
					)
				)
			) {
				markerObject.position.lat = parseFloat(markerObject.position.lat)
				markerObject.position.lng = parseFloat(markerObject.position.lng)
				return markerObject
			}
			return null
		})
		appointmentsMap = appointmentsMap.filter((item) => item !== null)
		appointmentsMap = appointmentsMap.reduce(function(rv, x) {
			(rv[`${x.position.lat},${x.position.lng}`] = rv[`${x.position.lat},${x.position.lng}`] || []).push(x);
			return rv;
		}, {})
		setMarkerGroups(Object.values(appointmentsMap))
	}, [appointments, clients, isUrgent, filterEmployee, filterDate, filterInvoiced, employees])

	useEffect(() => {
		if (map) {
			const bounds = new window.google.maps.LatLngBounds();
			markerGroups.forEach(group => {
				bounds.extend(group[0].position)
			})
			map.fitBounds(bounds)
		}
	}, [markerGroups, map])

	useEffect(() => {
		const mks = []
		for (let group of markerGroups) {
			let x = 0, y = 0;
			let incx = 1, incy = 0
			let cnt = 0, limit = 1, change = 0
			for (let marker of group) {
				mks.push({
					...marker,
					position: {
						lng: marker.position.lng + 30.0 * x / Math.pow(2, zoom),
						lat: marker.position.lat + 26.0 * y / Math.pow(2, zoom),
					}
				})
				x += incx
				y += incy
				cnt++
				if (cnt === limit) {
					cnt = 0
					change++
					const temp = incx
					incx = incy
					incy = temp
					if (change % 2 === 0) {
						limit++
						incx *= -1
						incy *= -1
					}
				}
			}
		}
		setMarkers(mks)
	}, [markerGroups, zoom])

	if (!isLoaded) {
		return <FaMapMarkerAlt />
	}

	function handleZoomChanged() {
		setZoom(this.getZoom())
	}

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			onLoad={onLoad}
			onZoomChanged={handleZoomChanged}
			options={{
				gestureHandling: "greedy"
			}}
		>
			{isUrgent}
			{markers.map((item) => {
				return (
					<CustomMarker
						key={item.appointment_id === highlighted_in_map ? -1 : item.idx}
						position={item.position}
						appointment={appointments[item.idx]}
						icon={
							{
								path: item.icon,
								scale: 0.05,
								strokeWeight: 0,
								fillColor: item.appointment_id === highlighted_in_map ? '#e8463d' : item.color,
								fillOpacity: 1.0,
							}
							// 	{
							// 	url: item.icon,
							// 	// scaledSize: new window.google.maps.Size(250, 250),
							// }
						}
						// size={{width:5, height:5}}
						handleDblClick={handleDblClick}
					/>
				)
			})}
			<CreateAppointmentModal show={show} setShow={setShow} />
		</GoogleMap>
	)
}

export default Map
