import { DateTime } from 'luxon'
import {
	DISPLAY_ALERT,
	CLEAR_ALERT,
	USER_LOGIN_BEGIN,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_ERROR,
	USER_LOGOUT,
	GET_DB_RECORDS_BEGIN,
	GET_DB_RECORDS_SUCCESS,
	GET_DB_RECORDS_ERROR,
	TOGGLE_URGENT,
	FILTER_EMPLOYEES,
	FILTER_DATE,
	TOGGLE_CALENDAR_VIEW,
	TOGGLE_LIST_VIEW,
	FORM_HANDLE_CHANGE,
	FORM_CLEAR_VALUES,
	CREATE_APPOINTMENT_BEGIN,
	CREATE_APPOINTMENT_SUCCESS,
	CREATE_APPOINTMENT_ERROR,
	PATCH_APPOINTMENT_BEGIN,
	PATCH_APPOINTMENT_SUCCESS,
	PATCH_APPOINTMENT_ERROR,
	DELETE_APPOINTMENT_BEGIN,
	DELETE_APPOINTMENT_SUCCESS,
	DELETE_APPOINTMENT_ERROR,
	SET_CURRENT_APPOINTMENT,
	CREATE_CLIENT_BEGIN,
	CREATE_CLIENT_SUCCESS,
	CREATE_CLIENT_ERROR,
	MODIFY_CLIENT_BEGIN,
	MODIFY_CLIENT_SUCCESS,
	MODIFY_CLIENT_ERROR,
	GET_LAT_LNG_BEGIN,
	GET_LAT_LNG_SUCCESS,
	GET_LAT_LNG_ERROR,
	SET_PENDING_LIST_COLLAPSED,
	HIGHLIGHT_APPOINTMENT_IN_LIST,
	HIGHLIGHT_APPOINTMENT_IN_MAP,
	FILTER_INVOICED,
	SET_VIEW_DATE,
} from './actions'
import { initialState } from './appContext'
import he from 'he'

const reducer = (state, action) => {
	if (action.type === DISPLAY_ALERT) {
		return {
			...state,
			showAlert: true,
			alertType: 'danger',
			alertText: 'Remplissez tous les champs!',
		}
	} else if (action.type === CLEAR_ALERT) {
		return {
			...state,
			showAlert: false,
			alertType: '',
			alertText: '',
		}
	} else if (action.type === USER_LOGIN_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === USER_LOGIN_SUCCESS) {
		const { token, id, calendar_id } = action.payload
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'success',
			alertText: 'Connecté! Rediriger...',
			token: token,
			id: id,
			calendar_id,
		}
	} else if (action.type === USER_LOGIN_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === USER_LOGOUT) {
		return {
			...initialState,
			token: null,
			id: null,
			calendar_id: null,
			calendarView: true,
			filterEmployee: [],
		}
	} else if (action.type === GET_DB_RECORDS_BEGIN) {
		return {
			...state,
			isLoading: true,
			[action.payload.recordName]: [],
		}
	} else if (action.type === GET_DB_RECORDS_SUCCESS) {
		if (action.payload.recordName === 'appointments') {
			return {
				...state,
				isLoading: false,
				appointments: action.payload.records.appointments,
				events: action.payload.records.events,
			}
		} else if (action.payload.recordName === 'clients') {
			return {
				...state,
				isLoading: false,
				clients: action.payload.records.map(client => {
					return {
						...client,
						title: {
							...(client.title || {}),
							rendered: he.decode(client.title?.rendered),
						}
					}
				})
			}
		}
		return {
			...state,
			isLoading: false,
			[action.payload.recordName]: action.payload.records,
		}
	} else if (action.type === GET_DB_RECORDS_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === TOGGLE_URGENT) {
		return {
			...state,
			isUrgent: !state.isUrgent,
		}
	} else if (action.type === FILTER_EMPLOYEES) {
		return {
			...state,
			filterEmployee: action.payload.employees,
		}
	} else if (action.type === FILTER_DATE) {
		return {
			...state,
			filterDate: action.payload.date,
		}
	} else if (action.type === FILTER_INVOICED) {
		return {
			...state,
			filterInvoiced: action.payload.invoiced,
		}
	} else if (action.type === SET_VIEW_DATE) {
		return {
			...state,
			viewDate: action.payload.date,
		}
	} else if (action.type === TOGGLE_CALENDAR_VIEW) {
		return {
			...state,
			calendarView: action.payload.value,
		}
	} else if (action.type === TOGGLE_LIST_VIEW) {
		return {
			...state,
			listView: action.payload.value,
		}
	} else if (action.type === FORM_HANDLE_CHANGE) {
		return {
			...state,
			formData: {
				...state.formData,
				[action.payload.name]: action.payload.value,
			},
		}
	} else if (action.type === FORM_CLEAR_VALUES) {
		return {
			...state,
			formData: initialState.formData,
		}
	} else if (action.type === SET_CURRENT_APPOINTMENT) {
		const time = action.payload.data?.date_time && DateTime.fromISO(action.payload.data.date_time).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })
		return {
			...state,
			formData: action.payload.data ? {
				...state.formData,
				...action.payload.data,
				hours: Math.floor(action.payload.data.duration / 60),
				minutes: action.payload.data.duration % 60,
				date: action.payload.data.date_time && DateTime.fromISO(action.payload.data.date_time).toISODate(),
				time: time === '00:00' ? null : time,
			} : initialState.formData
		}
	} else if (action.type === CREATE_APPOINTMENT_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === CREATE_APPOINTMENT_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'success',
			alertText: 'Rendez-vous ajouté avec succès!',
		}
	} else if (action.type === CREATE_APPOINTMENT_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === PATCH_APPOINTMENT_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === PATCH_APPOINTMENT_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'success',
			alertText: 'Rendez-vous attribué avec succès!',
		}
	} else if (action.type === PATCH_APPOINTMENT_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === DELETE_APPOINTMENT_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === DELETE_APPOINTMENT_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'success',
			alertText: 'Rendez-vous supprimé avec succès!',
		}
	} else if (action.type === DELETE_APPOINTMENT_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === CREATE_CLIENT_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === CREATE_CLIENT_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'success',
			alertText: 'Client ajouté avec succès!',
		}
	} else if (action.type === CREATE_CLIENT_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === MODIFY_CLIENT_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === MODIFY_CLIENT_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'success',
			alertText: 'Client attribué avec succès!',
		}
	} else if (action.type === MODIFY_CLIENT_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === GET_LAT_LNG_BEGIN) {
		return {
			...state,
			isLoading: true,
		}
	} else if (action.type === GET_LAT_LNG_SUCCESS) {
		return {
			...state,
			isLoading: false,
		}
	} else if (action.type === GET_LAT_LNG_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: 'danger',
			alertText: action.payload.message,
		}
	} else if (action.type === SET_PENDING_LIST_COLLAPSED) {
		return {
			...state,
			pendingListCollapsed: action.payload.value,
		}
	} else if (action.type === HIGHLIGHT_APPOINTMENT_IN_LIST) {
		return {
			...state,
			highlighted_in_list: action.payload.value,
		}
	} else if (action.type === HIGHLIGHT_APPOINTMENT_IN_MAP) {
		return {
			...state,
			highlighted_in_map: action.payload.value,
		}
	}

	throw new Error(`no such action: ${action.type}`)
}

export default reducer
