import { Container, Row, Col } from 'react-bootstrap'
import { useAppContext } from '../context/appContext'
import moment from 'moment'
import { TbUrgent } from 'react-icons/tb'

const AppointmentCard = ({
	appointment,
	text_class,
	highlighted,
	handleClick,
}) => {
	const {
		client_id,
		date_time,
		comment,
		urgent,
		appointment_id,
		createdAt,
	} = appointment
	const { clients, highlightAppointmentInMap } = useAppContext()
	const currentClient = clients.filter(
		(client) => client.id === parseInt(client_id)
	)[0]
	const clsHighlighted = highlighted ? 'highlighted' : ''
	return (
		<div className={`mb-1 ${text_class} ${clsHighlighted}`}>
			<Container
				className={text_class}
				onClick={() => handleClick(appointment)}
				onMouseOver={() => highlightAppointmentInMap(appointment_id)}
				onMouseOut={() => highlightAppointmentInMap(null)}
			>
				<Row>
					<Col>
						<div className='fw-bold'>{currentClient?.title?.rendered}</div>
						<div className='card-client-category'>{comment}</div>
					</Col>
					<Col xs={3} className='text-end text-danger fs-4'>
						{urgent && <TbUrgent />}
					</Col>
				</Row>
				<Row>
					<div className=''>{currentClient?.acf?.ville}</div>
				</Row>
				<Row className='color-x card-client-category'>
					<Col className='fw-bold'>{date_time && moment(date_time).format('D MMM YYYY')}</Col>
					<Col xs={4} className='text-end'>
						{moment(createdAt).format('[Créé le] L LT')}
					</Col>
				</Row>
				<div className='card-custom mt-2'></div>
			</Container>
		</div>
	)
}

export default AppointmentCard
