import { Form, Container, Alert } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../context/appContext'

const initialState = {
	username: '',
	password: '',
}

const Login = () => {
	const navigate = useNavigate()
	const [values, setValues] = useState(initialState)
	const {
		id,
		isLoading,
		showAlert,
		alertType,
		alertText,
		displayAlert,
		loginUser,
	} = useAppContext()

	const onSubmit = (e) => {
		e.preventDefault()
		const { username, password } = values
		if (!username || !password) {
			displayAlert()
			return
		}
		loginUser(values)
	}

	const handleChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		if (id) {
			setTimeout(() => {
				navigate('/')
			}, 3000)
		}
	}, [id, navigate])

	return (
		<div className='bg-x-dark p-5'>
			<Container className='w-25 bg-white p-5 rounded-5'>
				<Form className='' onSubmit={onSubmit}>
					<h2 className='mb-5 custom-txt'>Authentification</h2>
					<Alert className='text-center' show={showAlert} variant={alertType}>
						{alertText}
					</Alert>
					<Form.Group className='mb-3' controlId='formBasicusername'>
						<Form.Label className='small-label'>Identifiant</Form.Label>
						<Form.Control
							type='text'
							name='username'
							value={values.username}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label className='small-label'>Mot de passe</Form.Label>
						<Form.Control
							type='password'
							name='password'
							onChange={handleChange}
						/>
					</Form.Group>
					<div className='d-flex justify-content-center'>
						<button id="googleBtn" type='submit' disabled={isLoading}>
							<span className="icon"></span>
							<span className="buttonText">Sign in with Google</span>
						</button>
					</div>
				</Form>
			</Container>
		</div>
	)
}

export default Login
