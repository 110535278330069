import { Container, Row, Col } from 'react-bootstrap'
import { useAppContext } from '../context/appContext'
import { AiFillPlusCircle } from 'react-icons/ai'
import { CreateAppointmentModal } from '.'
import { useState } from 'react'

const HeaderLeftContainer = () => {
	const { toggleUrgent, listView, setCurrentAppointment, setPendingListCollapsed } = useAppContext()
	const [show, setShow] = useState(false)
	const [collapsed, setCollapsed] = useState(false)

	const handleShow = () => {
		setCurrentAppointment(null)
		setShow(true)
	}

	const handleCollapse = () => {
		setPendingListCollapsed(!collapsed)
		setCollapsed(!collapsed)
	}

	return (
		<Container className='bg-white header-all header-left text-center '>
			<Row className={listView === 'all' ? 'visible' : 'invisible'}>
				<Col>
					<button
						type='button'
						className='custom-btn text-lowercase fs-7'
						onClick={handleShow}
					>
						<AiFillPlusCircle size='1.5em' />{' '}
						<span className='text-capitalize'>Ajouter</span> un rendez-vous
					</button>
				</Col>
			</Row>
			<Row className={'form-check form-check-inline ' + (collapsed ? 'invisible' : '')}>
				{listView === 'all' ? (
					<label
						className='form-check-label fs-6c text-black mt-2'
						htmlFor='checkUrgent'
					>
						<input
							className='form-check-input'
							type='checkbox'
							id='checkUrgent'
							onChange={toggleUrgent}
						/>
						Urgent seulement
					</label>
				) : (
					<label
						className='form-check-label fs-6c text-black mt-2'
						htmlFor='checkUrgentInput'
					>
						<input
							className='form-check-input'
							type='checkbox'
							id='checkUrgentInput'
							// onChange={toggleUrgent}
						/>
						Urgent seulement
					</label>
				)}
			</Row>
			<Row>
				<h4 className='fw-bold color-x fs-4c mt-3'>
					Rendez vous en attente
					<span className='float-right' onClick={handleCollapse}>
						{
							collapsed
							? <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512" fill="#64ba8b"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
							: <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512" fill="#64ba8b"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>
						}
					</span>
				</h4>
			</Row>

			<CreateAppointmentModal show={show} setShow={setShow} />
		</Container>
	)
}

export default HeaderLeftContainer
