export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const USER_LOGIN_BEGIN = 'USER_LOGIN_BEGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'

export const USER_LOGOUT = 'USER_LOGOUT'

export const GET_DB_RECORDS_BEGIN = 'GET_DB_RECORDS_BEGIN'
export const GET_DB_RECORDS_SUCCESS = 'GET_DB_RECORDS_SUCCESS'
export const GET_DB_RECORDS_ERROR = 'GET_DB_RECORDS_ERROR'

export const TOGGLE_URGENT = 'TOGGLE_URGENT'
export const FILTER_EMPLOYEES = 'FILTER_EMPLOYEES'
export const FILTER_DATE = 'FILTER_DATE'
export const FILTER_INVOICED = 'FILTER_INVOICED'
export const TOGGLE_CALENDAR_VIEW = 'TOGGLE_CALENDAR_VIEW'
export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW'
export const SET_VIEW_DATE = 'SET_VIEW_DATE'

export const FORM_HANDLE_CHANGE = 'HANDLE_CHANGE'
export const FORM_CLEAR_VALUES = 'FORM_CLEAR_VALUES'

export const CREATE_APPOINTMENT_BEGIN = 'CREATE_APPOINTMENT_BEGIN'
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESSN'
export const CREATE_APPOINTMENT_ERROR = 'CREATE_APPOINTMENT_ERROR'

export const SET_CURRENT_APPOINTMENT = 'SET_CURRENT_APPOINTMENT'

export const PATCH_APPOINTMENT_BEGIN = 'PATCH_APPOINTMENT_BEGIN'
export const PATCH_APPOINTMENT_SUCCESS = 'PATCH_APPOINTMENT_SUCCESSN'
export const PATCH_APPOINTMENT_ERROR = 'PATCH_APPOINTMENT_ERROR'

export const DELETE_APPOINTMENT_BEGIN = 'DELETE_APPOINTMENT_BEGIN'
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_ERROR = 'DELETE_APPOINTMENT_ERROR'

export const CREATE_CLIENT_BEGIN = 'CREATE_CLIENT_BEGIN'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESSN'
export const CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR'

export const MODIFY_CLIENT_BEGIN = 'MODIFY_CLIENT_BEGIN'
export const MODIFY_CLIENT_SUCCESS = 'MODIFY_CLIENT_SUCCESS'
export const MODIFY_CLIENT_ERROR = 'MODIFY_CLIENT_ERROR'

export const GET_LAT_LNG_BEGIN = 'GET_LAT_LNG_BEGIN'
export const GET_LAT_LNG_SUCCESS = 'GET_LAT_LNG_SUCCESSN'
export const GET_LAT_LNG_ERROR = 'GET_LAT_LNG_ERROR'

export const SET_PENDING_LIST_COLLAPSED = 'SET_PENDING_LIST_COLLAPSED'

export const HIGHLIGHT_APPOINTMENT_IN_LIST = 'HIGHLIGHT_APPOINTMENT_IN_LIST'
export const HIGHLIGHT_APPOINTMENT_IN_MAP = 'HIGHLIGHT_APPOINTMENT_IN_MAP'
