import React, { useReducer, useContext, useEffect, useCallback } from 'react'
import axios from 'axios'
import { DateTime } from 'luxon'

import {
	DISPLAY_ALERT,
	CLEAR_ALERT,
	USER_LOGIN_BEGIN,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_ERROR,
	USER_LOGOUT,
	GET_DB_RECORDS_BEGIN,
	GET_DB_RECORDS_SUCCESS,
	GET_DB_RECORDS_ERROR,
	TOGGLE_URGENT,
	FILTER_EMPLOYEES,
	FILTER_DATE,
	TOGGLE_CALENDAR_VIEW,
	TOGGLE_LIST_VIEW,
	FORM_HANDLE_CHANGE,
	FORM_CLEAR_VALUES,
	CREATE_APPOINTMENT_BEGIN,
	CREATE_APPOINTMENT_SUCCESS,
	CREATE_APPOINTMENT_ERROR,
	PATCH_APPOINTMENT_BEGIN,
	PATCH_APPOINTMENT_SUCCESS,
	PATCH_APPOINTMENT_ERROR,
	DELETE_APPOINTMENT_BEGIN,
	DELETE_APPOINTMENT_SUCCESS,
	DELETE_APPOINTMENT_ERROR,
	SET_CURRENT_APPOINTMENT,
	CREATE_CLIENT_BEGIN,
	CREATE_CLIENT_SUCCESS,
	CREATE_CLIENT_ERROR,
	MODIFY_CLIENT_BEGIN,
	MODIFY_CLIENT_SUCCESS,
	MODIFY_CLIENT_ERROR,
	GET_LAT_LNG_BEGIN,
	GET_LAT_LNG_SUCCESS,
	GET_LAT_LNG_ERROR,
	SET_PENDING_LIST_COLLAPSED,
	HIGHLIGHT_APPOINTMENT_IN_LIST,
	HIGHLIGHT_APPOINTMENT_IN_MAP,
	FILTER_INVOICED,
	SET_VIEW_DATE,
} from './actions'
import reducer from './reducer'

const token = localStorage.getItem('token')
const id = localStorage.getItem('id')
const calendar_id = localStorage.getItem('calendar_id')
const calendarView = (localStorage.getItem('calendarView') || 'true') === 'true'
const filterEmployee = JSON.parse(localStorage.getItem('filterEmployee') || '[]')

const initialState = {
	isLoading: false,
	showAlert: false,
	alertText: '',
	alertType: '',
	id: id,
	token: token,
	calendar_id,
	clients: [],
	employees: [],
	appointments: [],
	events: [],
	categories: [],
	isUrgent: false,
	filterEmployee,
	filterDate: '',
	viewDate: new Date(),
	filterInvoiced: null,
	calendarView: calendarView,
	listView: 'all',
	formData: { lat: 48.85962, lng: 2.33659 },
	pendingListCollapsed: false,
	highlighted_in_list: null,
	highlighted_in_map: null,
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	// axios instance
	const authFetch = axios.create({
		baseURL: '/api/v1',
		withCredentials: true,
	})

	// authFetch request
	authFetch.interceptors.request.use(
		(config) => {
			config.headers.common['Authorization'] = `Bearer ${state.token}`
			return config
		},
		(error) => {
			return Promise.reject(error)
		}
	)

	// authFetch response
	authFetch.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			if (error.response.status === 303 && error.response.data && error.response.data.authUrl) {
				window.location.replace(error.response.data.authUrl)
				return Promise.reject()
			}
			if (error.response.status === 401 || error.response.status === 403) {
				logoutUser()
			}
			return Promise.reject(error)
		}
	)

	const displayAlert = () => {
		dispatch({ type: DISPLAY_ALERT })
		clearAlert()
	}

	const clearAlert = () => {
		setTimeout(() => {
			dispatch({ type: CLEAR_ALERT })
		}, 3000)
	}

	const addUserToLocalStorage = ({ id, token, calendar_id }) => {
		localStorage.setItem('id', id)
		localStorage.setItem('token', token)
		localStorage.setItem('calendar_id', calendar_id)
	}

	const removeUserFromLocalStorage = () => {
		localStorage.removeItem('id')
		localStorage.removeItem('token')
		localStorage.removeItem('calendar_id')
		document.cookie = "oauth2=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/api/v1;";
	}

	const saveViewModeToLocalStorage = (calendarView) => {
		localStorage.setItem('calendarView', calendarView)
	}

	const clearViewModeFromLocalStorage = () => {
		localStorage.removeItem('calendarView')
	}

	const saveFilterEmployeeToLocalStorage = (filterEmployee) => {
		localStorage.setItem('filterEmployee', JSON.stringify(filterEmployee))
	}

	const clearFilterEmployeeFromLocalStorage = () => {
		localStorage.removeItem('filterEmployee')
	}

	const loginUser = async (currentUser) => {
		dispatch({ type: USER_LOGIN_BEGIN })
		try {
			const user = await axios.post('/api/v1/auth', currentUser)
			const { token, id, calendar_id } = user.data.data
			dispatch({ type: USER_LOGIN_SUCCESS, payload: { token: token, id: id, calendar_id } })
			addUserToLocalStorage({ id, token, calendar_id })
		} catch (error) {
			dispatch({
				type: USER_LOGIN_ERROR,
				payload: { message: error.response.data.message },
			})
		}
		clearAlert()
	}

	const logoutUser = () => {
		dispatch({ type: USER_LOGOUT })
		clearViewModeFromLocalStorage()
		clearFilterEmployeeFromLocalStorage()
		removeUserFromLocalStorage()
	}

	const getAllRecords = async (id, recordName, calendar_id) => {
		let url
		// eslint-disable-next-line default-case
		switch (recordName) {
			case 'clients':
				url = `/clients?id=${id}`
				break

			case 'employees':
				url = `/employees?id=${id}`
				break

			case 'appointments':
				url = `/appointments?id=${id}&calendar_id=${calendar_id}`
				break

			case 'categories':
				url = `/categories?id=${id}`
				break
		}
		dispatch({
			type: GET_DB_RECORDS_BEGIN,
			payload: { recordName: recordName },
		})
		try {
			const result = await authFetch(url)
			dispatch({
				type: GET_DB_RECORDS_SUCCESS,
				payload: { recordName: recordName, records: result.data },
			})
		} catch (error) {
			dispatch({
				type: GET_DB_RECORDS_ERROR,
				payload: { message: error.message },
			})
			clearAlert()
		}
	}

	const toggleUrgent = () => {
		dispatch({ type: TOGGLE_URGENT })
	}

	const setFilterEmployee = (employees) => {
		saveFilterEmployeeToLocalStorage(employees)
		dispatch({
			type: FILTER_EMPLOYEES,
			payload: { employees },
		})
	}

	const setFilterDate = (date) => {
		dispatch({
			type: FILTER_DATE,
			payload: { date },
		})
	}

	const setFilterInvoiced = (invoiced) => {
		dispatch({
			type: FILTER_INVOICED,
			payload: { invoiced },
		})
	}

	const setViewDate = (date) => {
		dispatch({
			type: SET_VIEW_DATE,
			payload: { date },
		})
	}

	const toggleCalendarView = (value) => {
		saveViewModeToLocalStorage(value)
		dispatch({ type: TOGGLE_CALENDAR_VIEW, payload: { value: value } })
	}

	const toggleListView = (value) => {
		dispatch({ type: TOGGLE_LIST_VIEW, payload: { value: value } })
	}

	const handleChange = ({ name, value }) => {
		dispatch({
			type: FORM_HANDLE_CHANGE,
			payload: { name, value },
		})
	}

	const formClearValues = () => {
		dispatch({ type: FORM_CLEAR_VALUES })
	}

	const createAppointment = async ({
		created_by,
		client_id,
		employee_id,
		urgent,
		finished,
		invoiced,
		reminder,
		date,
		time,
		zone,
		duration,
		comment,
		location,
		attendees,
		client_name,
	}) => {
		dispatch({ type: CREATE_APPOINTMENT_BEGIN })
		try {
			await authFetch.post(`appointments?id=${state.id}&calendar_id=${state.calendar_id}`, {
				appointment: {
					created_by: created_by,
					client_id: client_id,
					employee_id: employee_id,
					urgent,
					finished,
					invoiced,
					reminder,
					date,
					time,
					zone,
					duration: duration,
					comment: comment,
				},
				calendar: {
					location: location,
					attendees: attendees,
					client_name: client_name,
				},
			})
			dispatch({ type: CREATE_APPOINTMENT_SUCCESS })
			getAllRecords(state.id, 'appointments', state.calendar_id)
		} catch (error) {
			dispatch({
				type: CREATE_APPOINTMENT_ERROR,
				payload: { message: error.response.data.message },
			})
		}
		clearAlert()
	}

	const patchAppointment = async (body) => {
		dispatch({ type: PATCH_APPOINTMENT_BEGIN })
		try {
			await authFetch.patch(`appointments/${body.appointment_id}?id=${state.id}&calendarId=${state.calendar_id}`, body)
			dispatch({ type: PATCH_APPOINTMENT_SUCCESS })
			getAllRecords(state.id, 'appointments', state.calendar_id)
		} catch (error) {
			dispatch({
				type: PATCH_APPOINTMENT_ERROR,
				payload: { message: error.response.data.message },
			})
		}
		clearAlert()
	}

	const deleteAppointment = async (data) => {
		dispatch({ type: DELETE_APPOINTMENT_BEGIN })
		try {
			await authFetch.delete(`appointments/${data.appointment_id}?id=${state.id}&calendarId=${state.calendar_id}`, { data })
			dispatch({ type: DELETE_APPOINTMENT_SUCCESS })
			getAllRecords(state.id, 'appointments', state.calendar_id)
		} catch (error) {
			dispatch({
				type: DELETE_APPOINTMENT_ERROR,
				payload: { message: error.response.data.message },
			})
		}
		clearAlert()
	}

	const setCurrentAppointment = (data) => {
		dispatch({
			type: SET_CURRENT_APPOINTMENT,
			payload: { data },
		})
	}

	const setPendingListCollapsed = (value) => {
		dispatch({
			type: SET_PENDING_LIST_COLLAPSED,
			payload: { value },
		})
	}

	const createClient = async ({
		client_name,
		category_id,
		address,
		town,
		zipcode,
		telephone,
		email,
		label,
		lat,
		lng,
	}) => {
		dispatch({ type: CREATE_CLIENT_BEGIN })
		try {
			const resp = await authFetch.post(`/clients?id=${state.id}`, {
				status: 'publish',
				type: 'client',
				author: parseInt(state.id),
				title: client_name,
				category_client: category_id ? [parseInt(category_id)] : [],
				fields: {
					adresse: address,
					code_postal: zipcode,
					ville: town,
					numero_de_telephone: telephone,
					email: email,
					localisation: {
						lat: lat,
						lng: lng,
						zoom: 12,
						markers: [
							{
								label: label,
								default_label: label,
								lat: lat,
								lng: lng,
							},
						],
						address: address,
						layers: ['OpenStreetMap.Mapnik'],
						version: '1.3.2',
						center_lat: lat,
						center_lng: lng,
					},
				},
			})
			dispatch({ type: CREATE_CLIENT_SUCCESS })
			getAllRecords(state.id, 'clients')
			handleChange({ name: 'client_id', value: resp.data.id })
		} catch (error) {
			dispatch({
				type: CREATE_CLIENT_ERROR,
				payload: { message: error.response.data.message },
			})
		}
		clearAlert()
	}

	const modifyClient = async ({
		client_id,
		client_name,
		category_id,
		address,
		town,
		zipcode,
		telephone,
		email,
		label,
		lat,
		lng,
	}) => {
		dispatch({ type: MODIFY_CLIENT_BEGIN })
		try {
			await authFetch.put(`/clients/${client_id}?id=${state.id}`, {
				status: 'publish',
				type: 'client',
				author: parseInt(state.id),
				title: client_name,
				category_client: category_id ? [parseInt(category_id)] : [],
				fields: {
					adresse: address,
					code_postal: zipcode,
					ville: town,
					numero_de_telephone: telephone,
					email: email,
					localisation: {
						lat,
						lng,
						zoom: 12,
						markers: [
							{
								label: label,
								default_label: label,
								lat,
								lng,
							},
						],
						address,
						layers: ['OpenStreetMap.Mapnik'],
						version: '1.3.2',
						center_lat: lat,
						center_lng: lng,
					},
				},
			})
			dispatch({ type: MODIFY_CLIENT_SUCCESS })
			getAllRecords(state.id, 'clients')
		} catch (error) {
			dispatch({
				type: MODIFY_CLIENT_ERROR,
				payload: { message: error.response.data.message },
			})
		}
		clearAlert()
	}

	const getLatLng = async (address) => {
		return new Promise(async (resolve, reject) => {
			try {
				dispatch({ type: GET_LAT_LNG_BEGIN })
				const response = await authFetch.post('/google', { address: address })
				if (response.data.length === 0) {
					throw new Error(`Impossible de trouver l'adresse`)
				}
				dispatch({ type: GET_LAT_LNG_SUCCESS })
				resolve(response.data[0])
			} catch (error) {
				dispatch({
					type: GET_LAT_LNG_ERROR,
					payload: { message: error.response?.data?.message || error.message },
				})
			}
			clearAlert()
		})
	}

	const highlightAppointmentInList = async (value) => {
		dispatch({
			type: HIGHLIGHT_APPOINTMENT_IN_LIST,
			payload: { value },
		})
	}

	const highlightAppointmentInMap = async (value) => {
		dispatch({
			type: HIGHLIGHT_APPOINTMENT_IN_MAP,
			payload: { value },
		})
	}

	const downloadHistory = useCallback(() => {
		fetch(`/api/v1/appointments/downloadHistory?id=${state.id}`, {
			headers: {
				'Authorization': `Bearer ${state.token}`,
			},
		}).then(res => res.blob())
		.then(blob => {
			const href = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', `historique_${DateTime.now().toFormat('yyyy-MM-dd-HH-mm')}.csv`)
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			URL.revokeObjectURL(href)
		})
	}, [state.id, state.token])

	useEffect(() => {
		if (state.id) {
			getAllRecords(state.id, 'clients')
			getAllRecords(state.id, 'employees')
			getAllRecords(state.id, 'appointments', state.calendar_id)
			getAllRecords(state.id, 'categories')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.id])

	return (
		<AppContext.Provider
			value={{
				...state,
				displayAlert,
				loginUser,
				logoutUser,
				toggleUrgent,
				setFilterEmployee,
				setFilterDate,
				setFilterInvoiced,
				setViewDate,
				toggleCalendarView,
				toggleListView,
				handleChange,
				formClearValues,
				createAppointment,
				patchAppointment,
				deleteAppointment,
				setCurrentAppointment,
				createClient,
				modifyClient,
				getLatLng,
				setPendingListCollapsed,
				highlightAppointmentInList,
				highlightAppointmentInMap,
				downloadHistory,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

const useAppContext = () => {
	return useContext(AppContext)
}

export { initialState, AppProvider, useAppContext }
