import { Card, Container, Row, Col } from 'react-bootstrap'
import { useAppContext } from '../context/appContext'
import { DateTime } from 'luxon'

const MapInfoCard = ({ client_id, date_time, appointment_id, comment, duration }) => {
	const { clients, toggleListView } = useAppContext()
	const currentClient = clients.filter(
		(client) => client.id === parseInt(client_id)
	)[0]
	return (
		<Card className='m-1'>
			<Container
				className='info-window fs-6c'
				onClick={() => {
					toggleListView(appointment_id)
				}}
			>
				<Row>
					<Col>
						<div className='fw-bold'>{currentClient?.title?.rendered}</div>
						<div className='card-client-category'>{comment}</div>
					</Col>
				</Row>
				<Row className='fw-bold card-client-category'>
					<Col>
						{date_time
							? `${DateTime.fromISO(date_time)
									.setLocale('fr')
									.toLocaleString(DateTime.DATE_FULL)} à ${DateTime.fromISO(
									date_time
							  )
									.setLocale('fr')
									.toLocaleString(DateTime.TIME_24_SIMPLE)}`
							: 'Pas de date'}
					</Col>
				</Row>
				{
					duration > 0 &&
					<Row className='fw-bold card-client-category'>
						<Col>Durée: {duration}mn</Col>
					</Row>
				}
			</Container>
		</Card>
	)
}

export default MapInfoCard
