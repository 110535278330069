import { Link } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import img from '../images/404.svg'

const Error = () => {
	return (
		<Container className='text-center fs-1'>
			<img src={img} alt='not found' />
			<p className='text-danger'>Page introuvable...</p>
			<Link to='/'>
				<Button variant='success'>revenir au monde réel</Button>
			</Link>
		</Container>
	)
}

export default Error
