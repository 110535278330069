const CalendarIcon = ({ size, color }) => {
	return (
		<svg
			version='1.0'
			xmlns='http://www.w3.org/2000/svg'
			width={size}
			height={size}
			viewBox='0 0 125 124'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0.000000,124.000000) scale(0.100000,-0.100000)'
				fill={color}
				stroke='none'
			>
				<path
					d='M256 1034 c-3 -9 -6 -31 -6 -50 0 -41 -7 -43 -135 -47 l-90 -2 0
-450 0 -450 499 -3 c360 -1 503 1 512 9 19 16 21 868 2 887 -8 8 -46 12 -103
12 -103 0 -115 6 -115 61 0 21 -5 41 -10 44 -19 12 -40 -17 -40 -55 0 -25 -5
-40 -16 -44 -9 -3 -109 -6 -223 -6 -225 0 -231 1 -231 55 0 45 -31 73 -44 39z
m722 -326 c17 -17 17 -589 0 -606 -17 -17 -869 -17 -886 0 -16 16 -17 570 -2
599 10 18 26 19 443 19 324 0 436 -3 445 -12z'
				/>
				<path
					d='M175 614 c-24 -25 -13 -38 36 -45 55 -8 69 -3 69 26 0 37 -74 50
-105 19z'
				/>
				<path
					d='M388 623 c-11 -3 -18 -14 -18 -27 0 -19 7 -24 36 -29 37 -6 84 6 84
22 0 31 -50 48 -102 34z'
				/>
				<path
					d='M592 618 c-25 -25 -14 -46 28 -53 50 -8 80 1 80 25 0 35 -80 56 -108
28z'
				/>
				<path
					d='M803 623 c-14 -5 -18 -52 -5 -54 57 -11 112 -1 112 21 0 30 -65 50
-107 33z'
				/>
				<path
					d='M178 489 c-38 -22 -15 -49 42 -49 41 0 60 10 60 30 0 29 -65 41 -102
19z'
				/>
				<path
					d='M388 493 c-22 -6 -24 -39 -2 -47 28 -11 70 -6 90 9 19 15 19 15 0 30
-19 15 -52 18 -88 8z'
				/>
				<path
					d='M591 483 c-23 -15 -23 -17 -6 -30 31 -22 115 -13 115 13 0 33 -71 44
-109 17z'
				/>
				<path
					d='M791 482 c-14 -26 5 -42 50 -42 47 0 69 8 69 26 0 35 -102 48 -119
16z'
				/>
				<path
					d='M171 356 c-10 -11 -7 -17 11 -30 12 -9 32 -16 43 -16 31 0 59 24 52
44 -8 21 -89 22 -106 2z'
				/>
				<path
					d='M377 363 c-32 -31 32 -62 80 -39 36 17 42 31 17 40 -21 8 -89 8 -97
-1z'
				/>
				<path
					d='M593 363 c-39 -15 13 -55 60 -46 36 7 56 31 36 44 -15 9 -75 11 -96
2z'
				/>
				<path
					d='M793 354 c-15 -38 70 -60 100 -26 24 26 4 42 -50 42 -31 0 -45 -5
-50 -16z'
				/>
				<path
					d='M174 236 c-13 -34 4 -47 53 -44 44 3 48 5 48 28 0 23 -4 25 -48 28
-35 2 -49 -1 -53 -12z'
				/>
				<path
					d='M374 235 c-12 -31 4 -45 50 -45 35 0 48 5 56 20 8 15 6 21 -9 30 -30
15 -91 13 -97 -5z'
				/>
				<path
					d='M590 240 c-12 -7 -12 -13 -4 -27 16 -25 92 -25 108 0 14 23 -6 37
-54 37 -19 0 -42 -5 -50 -10z'
				/>
				<path
					d='M794 236 c-13 -34 4 -47 53 -44 44 3 48 5 48 28 0 23 -4 25 -48 28
-35 2 -49 -1 -53 -12z'
				/>
			</g>
		</svg>
	)
}

export default CalendarIcon
