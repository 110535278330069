import { useAppContext } from '../context/appContext'
import { Map, CalendarView } from '.'

const RightViewContainer = () => {
	const { calendarView } = useAppContext()
	return <div className='pt-3'>{calendarView ? <CalendarView /> : <Map />}</div>
	// return <div className='pt-3'>{calendarView ? <CalendarEmbedded publicUrl='https://calendar.google.com/calendar/embed?src=horia.ologu@gmail.com' /> : <Map />}</div>
}

export default RightViewContainer
