import { Row, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react'
const MainRowComponent = ({ bg, componentLeft, componentRight, cmpLeftCollapsed }) => {
	const [leftWidth, setLeftWidth] = useState(4)
	const [heightClass, setHeightClass] = useState('')
	const [psClass, setPsClass] = useState('')
	const rightWidth = 12 - leftWidth
	const leftClasses = `ps-5 col-${leftWidth} ${heightClass}`
	const rightClasses = `${psClass} pe-5 ps-1 col-${rightWidth}`
	useEffect(() => {
		if (cmpLeftCollapsed) {
			setHeightClass('collapsed-to-top')
			setTimeout(() => {
				setPsClass('ps-5')
				setLeftWidth(0)
			}, 200)
		} else {
			setPsClass('')
			setLeftWidth(4)
			setTimeout(() => {
				setHeightClass('')
			}, 200)
		}
	}, [cmpLeftCollapsed])
	return (
		<Row className={bg}>
			<Col className={leftClasses}>
				{componentLeft}
			</Col>
			<Col className={rightClasses}>
				{componentRight}
			</Col>
		</Row>
	)
}

export default MainRowComponent
