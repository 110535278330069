import { Container, Alert, Row, Col } from 'react-bootstrap'
import {
	MainRowComponent,
	AppointmentsContainer,
	HeaderLeftContainer,
	HeaderRightContainer,
	RightViewContainer,
	AssignAppointment,
} from '../components'
import { Navigate } from 'react-router-dom'
import { useAppContext } from '../context/appContext'
import image from '../images/XERUX_logo_full_transparent.png'
import { SocialIcon } from 'react-social-icons'

const Dashboard = () => {
	const { id, logoutUser, alertText, alertType, showAlert, listView, pendingListCollapsed } =
		useAppContext()
	if (!id) {
		return <Navigate to='/login' />
	}
	const handleLogout = () => {
		if (window.confirm('Voulez-vous vous déconnecter?')) {
			logoutUser()
		}
	}
	return (
		// <Container className='test'>
		<Container fluid className='bg-light-custom test'>
			<Row className='bg-x-black p-row'>
				<Col className='p-3 p-left'>
					<img src={image} alt='Logo' className='img-style'/>
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					<a href='https://xerux.fr/prevenir/'>Prévenir</a>
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					<a href='https://xerux.fr/tournees/'>Planificateur</a>
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					<a href='https://xerux.fr/listing-client/'>Mes clients</a>
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					<a href='https://xerux.fr/my-messages/'>Mes Messages</a>
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					<a href='https://xerux.fr/account/'>Mon compte</a>
				</Col>
				<Col className='col-auto text-end align-self-center' style={{display: 'flex'}}>
					<button
						className='custom-btn-green custom-border-dark'
						type='button'
						onClick={handleLogout}
					>
						déconnecter
					</button>
				</Col>
			</Row>
			<Alert className='text-center' show={showAlert} variant={alertType}>
				{alertText}
			</Alert>
			<MainRowComponent
				bg='align-items-end pt-5'
				componentLeft={<HeaderLeftContainer />}
				componentRight={<HeaderRightContainer />}
			/>
			<MainRowComponent
				bg='bg-x-dark'
				cmpLeftCollapsed={pendingListCollapsed}
				componentLeft={
					listView === 'all' ? <AppointmentsContainer /> : <AssignAppointment />
				}
				componentRight={<RightViewContainer />}
			/>
			<Row className='bg-x-black p-row'>
				<Col className='p-3 col-4'>
					<img src={image} alt='Logo' className='img-style'/>
				</Col>
				<Col className='col-auto custom-links-txt align-self-center ps-0 pe-2 ms-0'>
					Accueil
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					Comment ça fonctionne
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>
					Les avantages
				</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>Tarifs</Col>
				<Col className='col-auto custom-links-txt align-self-center p-2'>Contact</Col>
				<Col className='col-auto text-end align-self-center p-2'>
					<button
						className='custom-btn-green custom-border-dark'
						type='button'
						// onClick={handleLogout}
					>
						s'abonner
					</button>
				</Col>
			</Row>
			<Row className='bg-black'>
				<Col className='pt-4'>
					<p className='fs-7 p-left-sm'>Copyright © 2021 XERUX</p>
				</Col>
				<Col className='text-end p-right-social pt-3 pb-3'>
					<SocialIcon
						network='wechat'
						bgColor='#64ba8b'
						style={{ height: '3vh', width: '3vh' }}
						className='m-2'
					></SocialIcon>
					<SocialIcon
						network='twitter'
						bgColor='#64ba8b'
						style={{ height: '3vh', width: '3vh' }}
						className='m-2'
					></SocialIcon>
					<SocialIcon
						network='facebook'
						bgColor='#64ba8b'
						style={{ height: '3vh', width: '3vh' }}
						className='m-2'
					></SocialIcon>
					<SocialIcon
						network='instagram'
						bgColor='#64ba8b'
						style={{ height: '3vh', width: '3vh' }}
						className='m-2'
					></SocialIcon>
					<SocialIcon
						network='linkedin'
						bgColor='#64ba8b'
						style={{ height: '3vh', width: '3vh' }}
						className='m-2'
					></SocialIcon>
					<SocialIcon
						network='youtube'
						bgColor='#64ba8b'
						style={{ height: '3vh', width: '3vh' }}
						className='m-2'
					></SocialIcon>
				</Col>
			</Row>
		</Container>
	)
}

export default Dashboard
