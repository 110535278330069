import { Modal, Form, Container, Row, Col, Alert } from 'react-bootstrap'
import { useState, useRef, useEffect } from 'react'
import { AiOutlinePlus, AiOutlineEdit } from 'react-icons/ai'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { DateTime } from 'luxon'
import { Typeahead } from 'react-bootstrap-typeahead'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import { useAppContext } from '../context/appContext'

const containerStyle = {
	width: '100%',
	height: '30vh',
	border: '2rem',
}

const SearchField = ({ locationHandler }) => {
	const provider = new OpenStreetMapProvider();
  
	// @ts-ignore
	const searchControl = new GeoSearchControl({
		provider: provider,
		style: 'bar',
		showMarker: false,
	});
  
	const map = useMap();
	useEffect(() => {
		map.addControl(searchControl)
		locationHandler && map.on('geosearch/showlocation', locationHandler)
		return () => map.removeControl(searchControl);
	}, []);
  
	return null;
};

const CreateAppointmentModal = ({ show, setShow }) => {
	const {
		showAlert,
		alertText,
		alertType,
		displayAlert,
		employees,
		clients,
		categories,
		createAppointment,
		patchAppointment,
		deleteAppointment,
		createClient,
		modifyClient,
		handleChange,
		formClearValues,
		formData,
		id,
		getLatLng,
	} = useAppContext()
	const [clientForm, setClientForm] = useState(false)
	const [showCustomerFile, setShowCustomerFile] = useState(false)
	const [customerData, setCustomerData] = useState({})
	const [center, setCenter] = useState({
		lat: 48.85962,
		lng: 2.33659,
	})
	const marker = useRef()

	const clientOptions = clients.map(c => {
		return {
			id: c.id,
			name: c.title?.rendered,
		}
	})

	const selectedClient = formData.client_id
		? clientOptions.filter(c => c.id == formData.client_id)
		: []

	const inputChange = (e) => {
		let { name, value } = e.target
		if (name === 'urgent' || name === 'finished' || name === 'invoiced' || name === 'reminder') {
			value = e.target.checked
		} else if (name === 'assignEmployeeRadio') {
			handleChange({ name: 'employee_id', value })
		}
		handleChange({ name, value })
	}

	const customerInputChange = (e) => {
		let { name, value } = e.target
		setCustomerData({
			...customerData,
			[name]: value,
		})
	}

	const handleClientSelectionChange = (selected) => {
		handleChange({
			name: 'client_id',
			value: selected.length ? selected[0].id : null
		})
	}

	const clickRadio = (e) => {
		if (formData.employee_id === e.target.value) {
			handleChange({ name: 'employee_id', value: null })
		}
	}

	const showClientForm = () => {
		setCustomerData({})
		setClientForm(true)
	}

	const hideClientForm = () => {
		setClientForm(false)
	}

	const hideCustomerFile = () => {
		setShowCustomerFile(false)
	}

	const handleClose = () => {
		formClearValues()
		setShow(false)
	}

	const handleDelete = () => {
		if (window.confirm('Voulez-vous supprimer ce rendez-vous?')) {
			const {
				calendar_id,
				appointment_id,
			} = formData
			deleteAppointment({
				calendar_id,
				appointment_id,
			})
			handleClose()
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (e.target.id === 'appointmentForm') {
			// add appointment form submit
			const {
				comment,
				client_id,
				urgent,
				finished,
				invoiced,
				reminder,
				date,
				time,
				hours,
				minutes,
				employee_id,
				calendar_id,
				appointment_id,
			} = formData
			const duration = parseInt(hours || 0) * 60 + parseInt(minutes || 0)
			if (!comment || !client_id) {
				displayAlert()
				return
			}
			const clientDetails =
				clients.find((client) => client.id === parseInt(client_id)) || null
			if (appointment_id) {
				patchAppointment({
					client_id: parseInt(client_id),
					appointment_id: appointment_id,
					employee_id: employee_id,
					calendar_id: calendar_id,
					date: date || null,
					time: time || null,
					duration: duration || 0,
					created_by: parseInt(id),
					urgent: urgent || false,
					finished: finished || false,
					invoiced: invoiced || false,
					reminder: reminder || false,
					comment: comment,
					location: clientDetails.acf?.localisation?.address || `pas d'adresse`,
					attendees: employee_id,
					client_name: clientDetails.title?.rendered,
					zone: DateTime.now().zoneName,
				})
			} else {
				createAppointment({
					created_by: parseInt(id),
					client_id: parseInt(client_id),
					employee_id: employee_id,
					urgent: urgent || false,
					finished: finished || false,
					invoiced: invoiced || false,
					reminder: reminder || false,
					date: date || null,
					time: time || null,
					zone: DateTime.now().zoneName,
					duration: duration || 0,
					comment: comment,
					location: clientDetails.acf?.localisation?.address || `pas d'adresse`,
					attendees: employee_id,
					client_name: clientDetails.title?.rendered,
				})
			}
			handleClose()
		} else if (e.target.id === 'clientForm') {
			// add client form submit
			const {
				client_name,
				category_id,
				address,
				town,
				zipcode,
				telephone,
				email,
				label,
				lat,
				lng,
			} = customerData

			if (
				!client_name ||
				!address ||
				!town ||
				!zipcode ||
				!telephone ||
				!label ||
				!lat ||
				!lng
			) {
				displayAlert()
				return
			}

			createClient({
				client_name,
				category_id,
				address,
				town,
				zipcode,
				telephone,
				email,
				label,
				lat,
				lng,
			})
			hideClientForm()
		} else {
			const {
				client_name,
				category_id,
				address,
				town,
				zipcode,
				telephone,
				email,
				label,
				lat,
				lng,
			} = customerData

			if (
				!client_name ||
				!address ||
				!town ||
				!zipcode ||
				!telephone ||
				!label ||
				!lat ||
				!lng
			) {
				displayAlert()
				return
			}

			modifyClient({
				client_id: formData.client_id,
				client_name,
				category_id,
				address,
				town,
				zipcode,
				telephone,
				email,
				label,
				lat,
				lng,
			})

			hideCustomerFile()
		}
	}

	const setAndShowCustomerFile = () => {
		const {
			title: { rendered: client_name },
			category_client: [category_id],
			acf: {
				email,
				adresse: address,
				ville: town,
				code_postal: zipcode,
				numero_de_telephone: telephone,
				localisation: {
					lat,
					lng,
					address: label,
				},
			},
		} = clients.find(c => c.id == formData.client_id)
		setCustomerData({
			client_name,
			category_id: category_id || '',
			address,
			town,
			zipcode,
			telephone,
			email,
			label,
			lat,
			lng,
		})
		setCenter({ lat, lng })
		setShowCustomerFile(true)
	}

	const handleBlur = () => {
		const { address, town, zipcode } = customerData
		getLatLng(`${address} ${town} ${zipcode}`).then((result) => {
			const { formatted_address, geometry: { location: { lat, lng } } } = result
			setCustomerData({
				...customerData,
				lat,
				lng,
				label: formatted_address
			})
			setCenter({ lat, lng })
		})
	}

	const onMarkerDragEnd = (e) => {
		const { lat, lng } = marker.current.getLatLng()
		setCustomerData({
			...customerData,
			lat,
			lng,
		})
	}

	const locationHandler = (e) => {
		const { marker: { _latlng: { lat, lng } }, location: { label } } = e
		setCustomerData({
			...customerData,
			lat,
			lng,
			label,
		})
	}

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: 'AIzaSyC0mQYJIKIR6hm5SET_cnTA00Bnpn1q_nM',
	})

	if (!isLoaded) {
		return <FaMapMarkerAlt />
	}

	return (
		<Modal show={show} onHide={handleClose} size='md' className='appointment-modal'>
			{/* <Modal.Header closeButton></Modal.Header> */}
			<Modal.Body>
				<Alert className='text-center' show={showAlert} variant={alertType}>
					{alertText}
				</Alert>
				{!clientForm && !showCustomerFile && (
					<Form onSubmit={handleSubmit} id='appointmentForm'>
						<h4 className='fw-bold color-x text-center fs-4c'>
							{ formData.appointment_id ? 'Modifier' : 'Ajouter' } un rendez vous
						</h4>
						<Container className='mb-5'>
							<Row className={formData.client_id ? 'align-items-end' : 'align-items-center'}>
								<Col className='col-11 col-sm-5'>
									<Form.Label className='small-label' htmlFor='clientSelect' style={{zIndex: 2}}>
										Client
									</Form.Label>
									<Typeahead
										id='clientSelect'
										aria-label='clientSelect'
										labelKey='name'
										onChange={handleClientSelectionChange}
										options={clientOptions}
										placeholder='Sélectionner le client'
										selected={selectedClient}
										/>
								</Col>
								<Col className='col-1 ps-0'>
									<button
										className='custom-btn fs-7 circle-btn'
										type='button'
										data-toggle='tooltip'
										data-placement='top'
										title='Ajouter un client'
										onClick={showClientForm}
									>
										<AiOutlinePlus />
									</button>
									{formData.client_id && <button
										className='custom-btn fs-7 circle-btn mt-0'
										type='button'
										data-toggle='tooltip'
										data-placement='top'
										title='Fiche client'
										onClick={setAndShowCustomerFile}
									>
										<AiOutlineEdit />
									</button>}
								</Col>
								<Col className='col-6 col-sm-3 ms-0 me-0'>
									<Form.Label className='small-label' htmlFor='date'>
										Date (facultatif)
									</Form.Label>
									<Form.Control
										type='date'
										name='date'
										onChange={inputChange}
										value={formData.date || ''}
									/>
								</Col>
								<Col className='col-6 col-sm-3 ms-0 me-0'>
									<Form.Label className='small-label' htmlFor='time'>
										Heure (facultatif)
									</Form.Label>
									<Form.Control
										type='time'
										name='time'
										onChange={inputChange}
										value={formData.time || ''}
									/>
								</Col>
							</Row>
							<Form.Label className='small-label'>Commentaire</Form.Label>
							<Form.Control
								as='textarea'
								name='comment'
								onChange={inputChange}
								value={formData.comment || ''}
							/>

							<h4 className='fw-bold color-x text-center mt-4 mb-0 fs-4c'>
								Durée (facultatif)
							</h4>
							<Row>
								<Col>
									<Form.Label className='small-label' htmlFor='hours'>
										Heures
									</Form.Label>
									<Form.Control
										type='text'
										name='hours'
										onChange={inputChange}
										value={formData.hours || ''}
									/>
								</Col>
								<Col>
									<Form.Label className='small-label' htmlFor='minutes'>
										Minutes
									</Form.Label>
									<Form.Control
										type='text'
										name='minutes'
										onChange={inputChange}
										value={formData.minutes || ''}
									/>
								</Col>
							</Row>

							<h4 className='fw-bold color-x text-center mt-4 fs-4c'>
								Attribuer à (facultatif)
							</h4>
							<div className='mb-4 text-body text-center'>
								{employees.map((employee) => (
									<label
										className='form-check-label pt-0 ps-2 text-body fs-6c'
										htmlFor={employee.email_address}
										key={employee.email_address + 'label'}
									>
										<input
											className='form-check-input me-1'
											type='radio'
											name='assignEmployeeRadio'
											value={employee.email_address}
											id={employee.email_address}
											key={employee.email_address}
											onClick={clickRadio}
											onChange={inputChange}
											checked={employee.email_address === formData.employee_id}
										/>
										{employee.nom_de_lemploye}
									</label>
								))}
							</div>
							<Row className='text-center'>
								<span>
									<label
										className='form-check-label fs-6c text-black'
										htmlFor='checkUrgent2'
									>
										<input
											className='form-check-input me-2'
											type='checkbox'
											id='checkUrgent2'
											name='urgent'
											onChange={inputChange}
											checked={formData.urgent || false}
										/>
										Urgent
									</label>
									<label
										className='form-check-label fs-6c text-black ms-3'
										htmlFor='checkFinished'
									>
										<input
											className='form-check-input me-2'
											type='checkbox'
											id='checkFinished'
											name='finished'
											onChange={inputChange}
											checked={formData.finished || false}
										/>
										Terminé
									</label>
									<label
										className='form-check-label fs-6c text-black ms-3'
										htmlFor='checkInvoiced'
									>
										<input
											className='form-check-input me-2'
											type='checkbox'
											id='checkInvoiced'
											name='invoiced'
											onChange={inputChange}
											checked={formData.invoiced || false}
										/>
										Facturé
									</label>
									<label
										className='form-check-label fs-6c text-black ms-3'
										htmlFor='checkReminder'
									>
										<input
											className='form-check-input me-2'
											type='checkbox'
											id='checkReminder'
											name='reminder'
											onChange={inputChange}
											checked={formData.reminder || false}
										/>
										Rappel SMS
									</label>
								</span>
							</Row>
						</Container>
						<div className='text-center'>
							<button className='custom-btn-green fs-7 me-5' type='submit'>
								{ formData.appointment_id ? 'enregistrer' : 'ajouter' }
							</button>
							<button
								className='custom-btn fs-7 hover-x'
								type='button'
								onClick={handleClose}
							>
								annuler
							</button>
						</div>
						{
							formData.appointment_id
							?
								<div className='text-center'>
									<button
										className='custom-btn-red fs-7 mt-2'
										type='button'
										onClick={handleDelete}>
										SUPPRIMER LE RDV
									</button>
								</div>
							:
								null
						}
					</Form>
				)}
				{clientForm && (
					<Form onSubmit={handleSubmit} id='clientForm'>
						<h4 className='fw-bold fs-4c color-x text-center mb-0'>
							Ajouter un client
						</h4>
						<Row>
							<Col>
								<Form.Label className='small-label' htmlFor='client_name'>
									Nom du client
								</Form.Label>
								<Form.Control
									type='text'
									name='client_name'
									onChange={customerInputChange}
								/>
							</Col>
							<Col>
								<Form.Label className='pt-0 invisible' htmlFor='category_id'>
									Catégorie de client
								</Form.Label>
								<Form.Select
									aria-label='clientSelect'
									name='category_id'
									onChange={customerInputChange}
									defaultValue=''
									data-live-search='true'
								>
									<option key={'category'} value='' disabled>
										Catégorie de client
									</option>
									{categories.map((category, idx) => {
										return (
											<option key={idx} value={category.category_id}>
												{category.category_name}
											</option>
										)
									})}
								</Form.Select>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col>
								<Form.Control
									type='text'
									name='address'
									placeholder='Adresse 1'
									onChange={customerInputChange}
									onBlur={handleBlur}
								/>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col>
								<Form.Control
									type='text'
									name='town'
									placeholder='Ville'
									onChange={customerInputChange}
									onBlur={handleBlur}
								/>
							</Col>
							<Col>
								<Form.Control
									type='text'
									name='zipcode'
									placeholder='Code postal'
									onChange={customerInputChange}
									onBlur={handleBlur}
								/>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col>
								<Form.Control
									type='text'
									name='telephone'
									placeholder='Téléphone'
									onChange={customerInputChange}
								/>
							</Col>
							<Col>
								<Form.Control
									type='email'
									name='email'
									placeholder='Adresse email'
									onChange={customerInputChange}
								/>
							</Col>
						</Row>
						<h4 className='fw-bold fs-4c color-x text-center mb-0 mt-3'>
							Localisation
						</h4>
						<GoogleMap
							mapContainerStyle={containerStyle}
							center={center}
							zoom={15}
						>
							<MarkerF
								key='maps-widget'
								position={center}
								icon={
									'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png'
								}
								// onClick={() => markerClick(item)}
							/>
						</GoogleMap>
						<div className='text-center mt-4'>
							<button className='custom-btn-green fs-7 me-5' type='submit'>
								enregistrer
							</button>
							<button
								className='custom-btn fs-7 hover-x'
								type='button'
								onClick={hideClientForm}
							>
								annuler
							</button>
						</div>
					</Form>
				)}
				{showCustomerFile && (
					<Form onSubmit={handleSubmit} id='customerFile'>
						<h4 className='fw-bold fs-4c color-x text-center mb-0'>
							Fiche client
						</h4>
						<Row>
							<Col>
								<Form.Label className='small-label' htmlFor='client_name'>
									Nom du client
								</Form.Label>
								<Form.Control
									type='text'
									name='client_name'
									value={customerData.client_name}
									onChange={customerInputChange}
								/>
							</Col>
							<Col>
								<Form.Label className='pt-0 invisible' htmlFor='category_id'>
									Catégorie de client
								</Form.Label>
								<Form.Select
									aria-label='clientSelect'
									name='category_id'
									onChange={customerInputChange}
									value={customerData.category_id}
									data-live-search='true'
								>
									<option key={'category'} value='' disabled>
										Catégorie de client
									</option>
									{categories.map((category, idx) => {
										return (
											<option key={idx} value={category.category_id}>
												{category.category_name}
											</option>
										)
									})}
								</Form.Select>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col>
								<Form.Control
									type='text'
									name='address'
									placeholder='Adresse 1'
									value={customerData.address}
									onChange={customerInputChange}
								/>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col>
								<Form.Control
									type='text'
									name='town'
									placeholder='Ville'
									value={customerData.town}
									onChange={customerInputChange}
								/>
							</Col>
							<Col>
								<Form.Control
									type='text'
									name='zipcode'
									value={customerData.zipcode}
									placeholder='Code postal'
									onChange={customerInputChange}
								/>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col>
								<Form.Control
									type='text'
									name='telephone'
									value={customerData.telephone}
									placeholder='Téléphone'
									onChange={customerInputChange}
								/>
							</Col>
							<Col>
								<Form.Control
									type='email'
									name='email'
									value={customerData.email}
									placeholder='Adresse email'
									onChange={customerInputChange}
								/>
							</Col>
						</Row>
						<h4 className='fw-bold fs-4c color-x text-center mb-0 mt-3'>
							Localisation
						</h4>
						<MapContainer center={Object.values(center)} zoom={15} style={containerStyle}>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							<Marker
								ref={marker}
								position={customerData}
								draggable={true}
								eventHandlers={{
									dragend: onMarkerDragEnd
								}}
							/>
							<SearchField locationHandler={locationHandler} />
						</MapContainer>
						<div className='text-center mt-4'>
							<button className='custom-btn-green fs-7 me-5' type='submit'>
								enregistrer
							</button>
							<button
								className='custom-btn fs-7 hover-x'
								type='button'
								onClick={hideCustomerFile}
							>
								annuler
							</button>
						</div>
					</Form>
				)}
			</Modal.Body>
		</Modal>
	)
}

export default CreateAppointmentModal
