import { useAppContext } from '../context/appContext'
import { Table, Form, Row, Col, Container } from 'react-bootstrap'
import moment from 'moment'
import { DateTime } from 'luxon'

const AssignAppointment = () => {
	const {
		listView,
		appointments,
		clients,
		toggleListView,
		employees,
		handleChange,
		patchAppointment,
		formData,
		displayAlert,
		formClearValues,
	} = useAppContext()
	const currentAppointment = appointments.filter(
		(appointment) => appointment.appointment_id === listView
	)[0]
	const currentClient = clients.filter(
		(client) => client.id === parseInt(currentAppointment.client_id)
	)[0]

	const inputChange = (e) => {
		handleChange({ name: e.target.name, value: e.target.value })
	}

	const hideAssignForm = () => {
		formClearValues()
		toggleListView('all')
	}

	const onSubmit = (e) => {
		e.preventDefault()
		let { employee_id, date, time } = formData

		if (!employee_id) {
			displayAlert()
			return
		}
		if (!date) {
			date = currentAppointment.date_time
				? currentAppointment.date_time.toString().slice(0, 10)
				: DateTime.now().toString().slice(0, 10)
			// if time is not set the appointment will stay All-Day in calendar
			time = currentAppointment.date_time
				? currentAppointment.date_time.toString().slice(12, 12)
				: '00:00:00.001'
		} else {
			time = date.slice(11, 16)
			date = date.slice(0, 10)
		}

		const date_time = DateTime.fromISO(`${date}T${time}`)
		if (currentAppointment.calendar_id) {
			patchAppointment({
				created_by: currentAppointment.created_by,
				appointment_id: currentAppointment.appointment_id,
				employee_id: employee_id,
				calendar_id: currentAppointment.calendar_id,
				date_time: date_time.toISO(),
				duration: currentAppointment.duration,
				attendees: employee_id,
				comment: currentAppointment.comment,
				location: currentClient.acf?.localisation?.address || `pas d'adresse`,
				client_name: currentClient.title?.rendered,
			})
		} else {
			patchAppointment({
				client_id: parseInt(currentAppointment.client_id),
				appointment_id: currentAppointment.appointment_id,
				employee_id: employee_id,
				calendar_id: null,
				date_time: date_time.toISO(),
				duration: currentAppointment.duration,
				created_by: currentAppointment.created_by,
				urgent: currentAppointment.urgent,
				comment: currentAppointment.comment,
				location: currentClient.acf?.localisation?.address || `pas d'adresse`,
				attendees: employee_id,
				client_name: currentClient.title?.rendered,
			})
		}
		hideAssignForm()
	}

	return (
		<Container className='pt-0 bg-white appointments fs-7 overflow-auto container-fluid'>
			<Row className='justify-content-center'>
				<Col className='col-11 fs-6c pt-0'>
					<Table borderless size='sm' className='pb-0 mb-0'>
						<tbody>
							<tr className='m-0'>
								<td className='color-x fw-bold col-6'>Client</td>
								<td>{currentClient?.title?.rendered}</td>
							</tr>
							<tr>
								<td className='color-x fw-bold col-6'>Adresse</td>
								<td>{currentClient?.acf?.adresse}</td>
							</tr>
							<tr>
								<td className='color-x fw-bold col-6'>Catégorie client</td>
								<td>{currentClient?.category_client}</td>
							</tr>
							<tr>
								<td className='color-x fw-bold col-6'>Date et heure</td>
								<td>
									{moment(currentAppointment.date_time).format('D MMM YYYY')}
								</td>
							</tr>
							<tr>
								<td className='color-x fw-bold col-4'>Commentaires</td>
								<td>{currentAppointment.comment}</td>
							</tr>
						</tbody>
					</Table>
					<Form onSubmit={onSubmit}>
						<p className='fw-bold fs-6 assign-form-label ms-1 mt-2'>
							Attribuer à
						</p>
						<div className='text-body text-center'>
							{employees.map((employee) => (
								<label
									className='form-check-label pt-3 ps-2 text-body'
									htmlFor={employee.email_address}
									key={employee.email_address}
								>
									<input
										className='form-check-input me-1'
										type='radio'
										name='employee_id'
										id={employee.email_address}
										value={employee.email_address}
										onChange={inputChange}
									/>
									{employee.nom_de_lemploye}
								</label>
							))}
						</div>
						<p className='fw-bold fs-6 assign-form-label ms-1 mt-1'>
							Date et heure
						</p>

						<input
							className='custom-txt full-date mt-3'
							type='datetime-local'
							name='date'
							id='appointmentDate'
							onChange={inputChange}
							onInput={inputChange}
							onSelect={inputChange}
							defaultValue={
								// format is 'YYYY-MM-DDThh:mm:ss'
								currentAppointment.date_time !== null
									? currentAppointment.date_time.toLocaleString().slice(0, 19)
									: // : ''
									  DateTime.now().toString().slice(0, 10) + 'T00:00:00'
							}
							// value={formData.date}
						/>
						{/* <input
							className='custom-txt date-time'
							type='time'
							name='time'
							step='300'
							id='appointmentTime '
							onChange={inputChange}
						/> */}
						<div className='text-center mt-4'>
							<button className='custom-btn-green' type='submit'>
								attribuer
							</button>
							<button
								className='custom-btn fs-7 hover-x'
								type='button'
								onClick={hideAssignForm}
							>
								annuler
							</button>
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default AssignAppointment
