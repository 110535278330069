import { useCallback, useMemo, useRef } from 'react'
import { Container, Col, Row, FormSelect, Dropdown, Form } from 'react-bootstrap'
import Select from 'react-select'
import { CalendarIcon, MapIcon } from '.'
import { useAppContext } from '../context/appContext'
import { DateTime } from 'luxon'

const FILTER_TEXTS = {
	'': 'Tous',
	'day': 'Aujourd\'hui',
	'week': 'Cette semaine',
}

const HeaderRightContainer = () => {
	const {
		calendarView,
		toggleCalendarView,
		employees,
		filterEmployee,
		setFilterEmployee,
		filterDate,
		setFilterDate,
		setFilterInvoiced,
		viewDate,
		setViewDate,
		downloadHistory,
	} = useAppContext()

	const dateInput = useRef(null)
	const dateItem = useRef(null)

	const dateFilterText = useMemo(() => {
		if (typeof filterDate === 'string') {
			return FILTER_TEXTS[filterDate]
		} else {
			return DateTime.fromJSDate(filterDate).setLocale('fr').toLocaleString(DateTime.DATE_FULL)
		}
	}, [filterDate])

	const toggleDateSel = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation()
		dateInput.current.showPicker()
	}, [dateInput])

	const handleDateChange = useCallback((e) => {
		if (e.target.value === '') {
			return
		}
		dateItem.current.click()
		const _viewDate = DateTime.fromISO(e.target.value).toJSDate()
		setViewDate(_viewDate)
		setFilterDate(_viewDate)
	}, [dateItem, setViewDate, setFilterDate])

	const inputChange = (e) => {
		if (e.target.name === 'filterInvoiced') {
			setFilterInvoiced(e.target.value === '' ? null : (e.target.value === 'true'))
		}
	}

	const onDownloadHistory = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation()
		downloadHistory()
	}, [downloadHistory])

	const options = useMemo(() => {
		return employees.map(emp => {
			return {
				value: emp.email_address,
				label: emp.nom_de_lemploye,
			}
		});
	}, [employees])

	const selectedOptions = useMemo(() => {
		return options.filter(option => filterEmployee.indexOf(option.value) !== -1)
	}, [options, filterEmployee])

	return (
		<Container className='text-center header-all'>
			<Row>
				<h4 className='fw-bold color-x fs-4c'>
					Vue {calendarView ? 'calendrier' : 'carte des rendez vous'}
				</h4>
			</Row>
			<Row className='justify-content-md-center mb-3'>
				<Col
					md='auto'
					className='p-0 m-0'
					onClick={() => toggleCalendarView(false)}
				>
					<MapIcon size={30} color={calendarView ? '#b6ae99' : '#64ba8b'} />
				</Col>
				<Col
					md='auto'
					className='p-0 m-0'
					onClick={() => toggleCalendarView(true)}
				>
					<CalendarIcon
						size={30}
						color={calendarView ? '#64ba8b' : '#b6ae99'}
					/>
				</Col>
			</Row>
			<Row className='justify-content-md-center m-0 p-0'>
				{/* <Col md='auto' className='fw-bold fs-5 assign-form-label'>
					Attribuer à :
				</Col> */}
				<Col md='4' className='fs-6c p-1'>
					{/* <FormLabel className='small-label' htmlFor='filterEmployee'>
						Attribuer à :
					</FormLabel> */}
					<Select
						placeholder="Utilisateurs (trices)"
						defaultValue={[]}
						options={options}
						value={selectedOptions}
						isMulti={true}
						styles={{
							menu: (baseStyles, state) => ({
								...baseStyles,
								zIndex: 100,
							}),
						}}
						onChange={v => setFilterEmployee(v.map(o => o.value))}
					/>
				</Col>
				<Col md='2' className='fs-6c p-1'>
					{/* <FormLabel className='small-label' htmlFor='filterDay'>
						Journée :
					</FormLabel> */}
					<Dropdown>
						<Dropdown.Toggle variant='outline-secondary'>
							{dateFilterText}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{Object.keys(FILTER_TEXTS).map(filter => (
								<Dropdown.Item
									key={filter}
									onClick={() => setFilterDate(filter)}
								>
									{FILTER_TEXTS[filter]}
								</Dropdown.Item>
							))}
							<Dropdown.Item
								onClick={() => setFilterDate(viewDate)}
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
								ref={dateItem}
							>
								<Form.Control
									className='date-filter'
									type='date'
									name='date'
									value={viewDate.toISOString().substr(0, 10)}
									ref={dateInput}
									onChange={handleDateChange}
								/>
								<span
									onClick={toggleDateSel}
									style={{ marginLeft: 10 }}
								>
									<CalendarIcon
										size={30}
										color='#64ba8b'
									/>
								</span>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
				<Col md='2' className='fs-6c p-1 d-flex align-items-center'>
					<FormSelect
						aria-label='filterInvoiced'
						name='filterInvoiced'
						onChange={inputChange}
					>
						<option key='all' value=''>
							Facturé - Tous
						</option>
						<option key='invoiced' value={true}>
							Facturé - Oui
						</option>
						<option key='not_invoiced' value={false}>
							Facturé - Non
						</option>
					</FormSelect>
				</Col>
				<Col md='1' className='mx-4 fs-6c p-1 d-flex align-items-center'>
					<a href='/' onClick={onDownloadHistory}>Historique</a>
				</Col>
			</Row>
		</Container>
	)
}

export default HeaderRightContainer
